/* Common Imports */

import { styled, useTheme } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
import {
	setBusinessDashboardListingsFilterTransactionTypeThunk,
	setHomeLoadingThunk,
} from "../../../redux-magic/thunks";

/* Component Imports */
import { Chip, SvgIcon, Typography } from "@mui/material";
import { useRouter } from "next/router";
import BusinessProfileCardsSkeletonDesktop from "../../skeletons/home/desktop/BusinessProfileCardsSkeletonDesktop";

/* Icon Imports */

// import BeegruButton from "../../common-components/buttons/BeegruButton";
import KYCBadge from "/public/images/badges/kyc_verified.svg";
import StarIcon from "/public/images/badges/starIcon.svg";
import PremiumBadge from "/public/images/badges/verified_premium.svg";
import Rent from "/public/images/icons/home/rent.svg";
import Sale from "/public/images/icons/home/sale.svg";

/* Function Imports */

import BusinessProfileTypeString from "../../../lib/BusinessProfileTypeString";
import PreparePriceString from "../../../lib/PreparePriceString";

/* Styled Components */

const BusinessProfileCardLink = styled("a", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	textDecoration: "none",
	cursor: "pointer",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:focus, &:hover, &:visited, &:link, &:active": {
		textDecoration: "none",
	},
	width: source === "carousel" ? "auto" : "100%",
	"@media (pointer: fine)": {
		transition: "all 0.2s ease",
		"&:hover": {
			// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
			boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
			transform: "scale(1.01)",
		},
	},

	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		width: source !== "carousel" ? "48%" : "auto",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		width: source !== "carousel" ? "31.75%" : "auto",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 2K breakpoint  2560 ~~4 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: source !== "carousel" ? "23.75%" : "auto",
	},
}));

const CardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0.75rem",
	gap: "1rem",
	borderRadius: "1rem",
	background: theme.palette.background.paper,
}));

const ColumnContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "0rem",
}));

const RowContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
}));

const ProfilePicture = styled("img")(({ theme }) => ({
	width: "4.5rem",
	height: "4.5rem",
	borderRadius: "0.75rem",
	objectFit: "cover",
	border: "1px solid #d6cfc9",
	[theme.breakpoints.up("md1190")]: {
		height: "5.25rem",
		width: "5.25rem",
		borderRadius: "1rem",
	},
})) as any;

const BusinessTitle = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	fontWeight: 400,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
		lineHeight: "1.125rem",
	},
}));

const BusinessType = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	fontWeight: 400,
	lineHeight: "0.875rem",
	color: theme.palette.text.secondary,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "0.875rem",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 500,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.25rem",
	},
}));

const CustomChip = styled(Chip)(({ theme }) => ({
	"& .MuiChip-label": {
		padding: "0rem 0.5rem 0rem 0.5rem",
	},
	"& .MuiChip-avatar": {
		width: "1rem",
		height: "1rem",
	},
	height: "1.35rem",
	padding: "0rem",
	fontWeight: 500,
	fontSize: "0.75rem",
	borderRadius: "0.5rem",
	backgroundColor: "#dbf1e8",
	color: theme.palette.text.primary,
}));

const RatingContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.25rem",
}));

const ListDetailsContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	alignSelf: "stretch",
	width: "100%",
	padding: "0.5rem 0.5rem",
	borderRadius: "8px",
	border: "1px solid #B05911",
	background: "#FFFFFF",
	// boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
	cursor: "pointer",
	gap: "2.5rem",
}));

const HeadContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	fontWeight: 400,
	lineHeight: "150%",
	color: theme.palette.text.secondary,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "0.875rem",
	},
}));

const BusinessProfileCard = ({
	_id,
	source,
	dispatch,
	bp_carousel_width_height,
	session,
}: {
	_id: ObjectId | string;
	source?: string;
	dispatch: Function;
	bp_carousel_width_height: string;
	session: any;
}) => {
	const theme = useTheme();

	const router = useRouter();

	const [businessData, setBusinessData] = React.useState<any>({});

	const [loading, setLoading] = React.useState(true);

	const is_subscribed: boolean = businessData
		? businessData.subscription_information
			? businessData.subscription_information.active
				? businessData.subscription_information.active
				: false
			: false
		: false;

	const localLang = {
		verified: router.locale === "ar-AE" ? "تم التحقق" : router.locale === "kn-IN" ? "ಪರಿಶೀಲಿಸಲಾಗಿದೆ" : "Verified",
		premium_user:
			router.locale === "ar-AE" ? "مستخدم مميز" : router.locale === "kn-IN" ? "ಪ್ರೀಮಿಯಂ ಬಳಕೆದಾರ" : "Premium User",
		propertiesForSale: router.locale === "ar-AE" ? "للبيع" : router.locale === "kn-IN" ? "ಮಾರಾಟಕ್ಕೆ" : "Sale",
		services: router.locale === "ar-AE" ? "الخدمات" : router.locale === "kn-IN" ? "ಸೇವೆಗಳು" : "Services",
		propertiesForRent: router.locale === "ar-AE" ? "للإيجار" : router.locale === "kn-IN" ? "ಬಾಡಿಗೆಗೆ" : "Rent",
		share: router.locale === "ar-AE" ? "شارك" : router.locale === "kn-IN" ? "ಹಂಚಿಕೊಳ್ಳಿ" : "Share",
		have_a_look_at_this_profile_on_beegru:
			router.locale === "ar-AE" ? "انظر إلى هذا الملف على Beegru" : "Have a look at this profile on Beegru",
		whatsApp: router.locale === "ar-AE" ? "واتساب" : router.locale === "kn-IN" ? "ವಾಟ್ಸಪ್" : "WhatsApp",
		viewNumber:
			router.locale === "ar-AE" ? "عرض الرقم" : router.locale === "kn-IN" ? "ಸಂಖ್ಯೆಯನ್ನು ವೀಕ್ಷಿಸಿ" : "View Number",
		callNow: router.locale === "ar-AE" ? "اتصل الآن" : router.locale === "kn-IN" ? "ಈಗ ಕರೆ ಮಾಡಿ" : "Call now",
	};

	/* Calculate average rating */

	const [avg, setAvg] = React.useState<number>(0);

	React.useEffect(() => {
		if (_id) {
			const url = `${process.env.PRODUCTION_API_URL}business-profiles/cards/${_id}${
				bp_carousel_width_height ? `?resize=${bp_carousel_width_height.toString()}` : ""
			}`;

			setLoading(true); // ✅ Explicitly set loading to true before fetch starts

			fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setBusinessData(data.BusinessProfileData);
					setAvg(data.BusinessProfileData.reviewsDetails.avrage);
					setLoading(false); // ✅ Only set loading to false after data is received

					if (source === "carousel") {
						dispatch(setHomeLoadingThunk({ loading_top_business_profiles: false }));
					}
				})
				.catch((err) => {
					console.error("Fetch error:", err);
					setLoading(false); // ✅ Prevent infinite loading state
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_id, bp_carousel_width_height, source, dispatch]);

	if (loading) {
		/* Skeleton loading state */

		return <BusinessProfileCardsSkeletonDesktop source={source ? source : ""} />;
	} else {
		/* Card */

		return (
			<React.Fragment>
				<BusinessProfileCardLink
					id={"home_top_realtors_" + businessData.slug}
					href={`${process.env.PRODUCTION_URL || ""}loading.html?callbackUrl=${encodeURIComponent(
						`${process.env.PRODUCTION_URL || ""}${router.locale}/${businessData.business_profile_type}s/${businessData.slug}`,
					)}&locale=${router.locale || "en-IN"}`}
					rel="noopener"
					target="_self"
					referrerPolicy="no-referrer"
					source={source ? source : ""}
					title={businessData.title ? businessData.title : "Business profile"}
					sx={{
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
					}}
				>
				<CardContainer source={source ? source : ""}>
					<RowContainer
						sx={{
							width: "100%",
							gap: "1rem",
							alignItems: "flex-start",
						}}
					>
						{/* Profile Picture */}

						{!businessData.display_picture_url?.includes("") ? (
							<ProfilePicture
								src={businessData.display_picture_url}
								alt="Profile Picture"
								width={32}
								height={32}
								loading="lazy"
								referrerPolicy="no-referrer"
								fetchpriority="high"
							/>
						) : (
							<ProfilePicture
								src={"/images/icons/Avatar.svg"}
								alt="Profile Picture"
								width={32}
								height={32}
								loading="lazy"
								referrerPolicy="no-referrer"
								fetchpriority="high"
							/>
						)}

						<ColumnContainer
							sx={{
								width: "100%",
								gap: "0.25rem",
							}}
						>
							<RowContainer sx={{ justifyContent: "space-between", gap: "0.5rem", width: "100%" }}>
								{/* Title */}

								<HeadContent>
									<BusinessTitle variant="body1">
										{businessData.title
											? businessData.title.length > (source === "search" ? 16 : 14)
												? businessData.title.substring(0, source === "search" ? 16 : 14) + "..."
												: businessData.title
											: ""}
									</BusinessTitle>

									{/* Premium Badge */}

									{is_subscribed ? (
										<SvgIcon
											component={PremiumBadge}
											inheritViewBox={false}
											viewBox="0 0 24 24"
											width={24}
											height={24}
											sx={{
												width: "1rem",
												height: "1rem",
											}}
										/>
									) : null}
								</HeadContent>
							</RowContainer>

							<RowContainer sx={{ gap: "0.75rem" }}>
								{/* Business Profile Type */}

								<BusinessType variant="body2">
									{BusinessProfileTypeString(businessData.business_profile_type, router)}
								</BusinessType>
							</RowContainer>

							{/* Rating */}
							{businessData.reviewsDetails.reviewsCount > 0 && (
								<RatingContainer>
									<SvgIcon
										component={StarIcon}
										inheritViewBox={false}
										viewBox="0 0 16 16"
										width={24}
										height={24}
										sx={{
											width: "1rem",
											height: "1rem",
										}}
									/>
									<SubText
										variant="body2"
										sx={{
											fontSize: "0.75rem",
											lineHeight: "166%",
											letterSpacing: "0.4px",
											color: theme.palette.text.primary,
										}}
									>
										{businessData.reviewsDetails.average.toFixed(1) +
											"(" +
											PreparePriceString(businessData.reviewsDetails.reviewsCount) +
											")"}
									</SubText>
								</RatingContainer>
							)}
							{/* Chips */}

							<RowContainer
								sx={{
									margin: "0.5rem 0rem 0rem 0rem",
									[theme.breakpoints.up("md1190")]: {
										margin: "0.75rem 0rem 0rem 0rem",
									},
								}}
							>
								{/* KYC Verified Chip */}

								{businessData.kyc_info.kyc_status === "kyc_verified" ? (
									<CustomChip
										avatar={
											<SvgIcon
												component={KYCBadge}
												inheritViewBox={false}
												viewBox="0 0 24 24"
												width={24}
												height={24}
											/>
										}
										label={localLang.verified}
									/>
								) : null}
							</RowContainer>
						</ColumnContainer>
					</RowContainer>

					{/* Listing Stats */}

					<RowContainer
						sx={{
							justifyContent: "space-between",
							width: "100%",
							gap: "1rem",
						}}
					>
						{/* Properties for Sale */}

						{businessData.business_profile_type === "professional" ? (
							<BusinessProfileCardLink
								id={"home_top_realtors_" + businessData.slug}
								href={`${process.env.PRODUCTION_URL || ""}loading.html?callbackUrl=${encodeURIComponent(
									`${process.env.PRODUCTION_URL || ""}${router.locale}/${businessData.business_profile_type}s/${businessData.slug}?filter_transaction_type=sale`,
								)}&locale=${router.locale || "en-IN"}`}
								rel="noopener"
								target="_self"
								referrerPolicy="no-referrer"
								source={source ? source : ""}

							>
								<ListDetailsContent>
									<ColumnContainer sx={{ gap: "0.125rem" }}>
										<Text
											sx={{
												fontSize: "0.875rem",
												lineHeight: "0.875rem",
												[theme.breakpoints.up("md1190")]: {
													fontSize: "0.875rem",
													lineHeight: "0.875rem",
												},
											}}
										>
											{businessData.listingServicesCount}
										</Text>
										<Text
											sx={{
												fontSize: "0.625rem",
												lineHeight: "0.875rem",
												color: theme.palette.text.secondary,
												[theme.breakpoints.up("md1190")]: {
													fontSize: "0.75rem",
													lineHeight: "1rem",
												},
											}}
										>
											{localLang.services}
										</Text>
									</ColumnContainer>
									<SvgIcon
										component={Sale}
										inheritViewBox={false}
										viewBox="0 0 39 38"
										width={39}
										height={38}
										sx={{
											fill: "none",
											width: "1.875rem",
											height: "1.875rem",
											[theme.breakpoints.up("md1190")]: {
												width: "2rem",
												height: "2rem",
											},
										}}
									/>
								</ListDetailsContent>
							</BusinessProfileCardLink>
						) : (
							<BusinessProfileCardLink
								id={"home_top_realtors_" + businessData.slug}
								href={`${process.env.PRODUCTION_URL || ""}loading.html?callbackUrl=${encodeURIComponent(
									`${process.env.PRODUCTION_URL || ""}${router.locale}/${businessData.business_profile_type}s/${businessData.slug}?filter_transaction_type=sale`,
								)}&locale=${router.locale || "en-IN"}`}
								rel="noopener"
								target="_self"
								referrerPolicy="no-referrer"
								source={source ? source : ""}

							>
								<ListDetailsContent>
									<ColumnContainer sx={{ gap: "0.125rem" }}>
										<Text
											sx={{
												fontSize: "0.875rem",
												lineHeight: "0.875rem",
												[theme.breakpoints.up("md1190")]: {
													fontSize: "0.875rem",
													lineHeight: "0.875rem",
												},
											}}
										>
											{businessData.listingPropertiesSaleCount}
										</Text>
										<Text
											sx={{
												fontSize: "0.625rem",
												lineHeight: "0.875rem",
												color: theme.palette.text.secondary,
												[theme.breakpoints.up("md1190")]: {
													fontSize: "0.75rem",
													lineHeight: "1rem",
												},
											}}
										>
											{localLang.propertiesForSale}
										</Text>
									</ColumnContainer>
									<SvgIcon
										component={Sale}
										inheritViewBox={false}
										viewBox="0 0 39 38"
										width={39}
										height={38}
										sx={{
											fill: "none",
											width: "1.875rem",
											height: "1.875rem",
											[theme.breakpoints.up("md1190")]: {
												width: "2rem",
												height: "2rem",
											},
										}}
									/>
								</ListDetailsContent>
							</BusinessProfileCardLink>
						)}

						{/* Properties for Rent */}

						{businessData.business_profile_type === "professional" ? null : (
							<BusinessProfileCardLink
								id={"home_top_realtors_" + businessData.slug}
								href={`${process.env.PRODUCTION_URL || ""}loading.html?callbackUrl=${encodeURIComponent(
									`${process.env.PRODUCTION_URL || ""}${router.locale}/${businessData.business_profile_type}s/${businessData.slug}?filter_transaction_type=rent`,
								)}&locale=${router.locale || "en-IN"}`}
								rel="noopener"
								target="_self"
								referrerPolicy="no-referrer"
								source={source ? source : ""}
								onClick={() => {
									dispatch(setBusinessDashboardListingsFilterTransactionTypeThunk("rent"));

								}}
							>
								<ListDetailsContent>
									<ColumnContainer sx={{ gap: "0.125rem" }}>
										<Text
											sx={{
												fontSize: "0.875rem",
												lineHeight: "0.875rem",
												[theme.breakpoints.up("md1190")]: {
													fontSize: "0.875rem",
													lineHeight: "0.875rem",
												},
											}}
										>
											{businessData.listingsPropertiesRentCount}
										</Text>
										<Text
											sx={{
												fontSize: "0.625rem",
												lineHeight: "0.875rem",
												color: theme.palette.text.secondary,
												[theme.breakpoints.up("md1190")]: {
													fontSize: "0.75rem",
													lineHeight: "1rem",
												},
											}}
										>
											{localLang.propertiesForRent}
										</Text>
									</ColumnContainer>
									<SvgIcon
										component={Rent}
										inheritViewBox={false}
										viewBox="0 0 62 61"
										width={62}
										height={61}
										sx={{
											fill: "none",
											width: "1.875rem",
											height: "1.875rem",
											[theme.breakpoints.up("md1190")]: {
												width: "2rem",
												height: "2rem",
											},
										}}
									/>
								</ListDetailsContent>
							</BusinessProfileCardLink>
						)}
					</RowContainer>

					{/* CTA Buttons */}

					{/* <RowContainer
							sx={{
								justifyContent: "space-between",
								width: "100%",
								gap: "1rem",
							}}
						>
							<Link
								href={`https://wa.me/${String(businessData.phone).replace(" ", "").replace("+", "")}`}
								rel="noopener"
								target="_self"
								referrerPolicy="no-referrer"
								aria-label="Share via Whatsapp"
								sx={{
									textDecoration: "none",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									margin: "0.25rem 0.5rem 0.25rem 0.5rem",
									border: "1px solid #24D366",
									borderRadius: "0.5rem",
									color: "#24D366",
									width: "100%",
									gap: "0.5rem",
								}}
							>
								<WhatsApp
									style={{
										color: "#24D366",
										width: "1.25rem",
										height: "1.25rem",
										margin: "0.25rem 0rem 0.25rem 0rem",
									}}
								/>
								{localLang.whatsApp}
							</Link>

							<BeegruButton
								variant="outlined"
								flavour="primary"
								href={"tel:" + businessData.phone.country_code + " " + businessData.phone.phone_number}
								onClick={(event: any) => {
									// session !== null ? submit(true) : null;
									if (isDesktop) {
										event.preventDefault();
										setShowPhone(!showPhone);
									}
								}}
								sx={{
									width: "100%",
									borderRadius: "0.5rem",
									fontSize: "0.875rem",
									lineHeight: "1.25rem",
									letterSpacing: "0.15px",
									padding: "0rem",
									border: "1px solid #B05911",
									color: "#B05911",
									gap: "0.5rem",
									background: "rgba(252, 128, 25, 0.16)",
								}}
							>
								<CallOutlined
									style={{
										color: "#B05911",
										width: "1.25rem",
										height: "1.25rem",
										margin: "0.25rem 0rem 0.25rem 0rem",
									}}
								/>
								<SubText sx={{ color: "#B05911" }}>
									{showPhone
										? businessData.phone.country_code + " " + businessData.phone.phone_number
										: isDesktop
											? localLang.viewNumber
											: localLang.callNow}
								</SubText>
							</BeegruButton>
						</RowContainer> */}
				</CardContainer>
				</BusinessProfileCardLink>
			</React.Fragment>
		);
	}
};

export default BusinessProfileCard;
