/* mui Imports */
import { Chip, Skeleton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/system";

const BusinessProfileCardLinkSkeleton = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:focus, &:hover, &:visited, &:link, &:active": {
		textDecoration: "none",
	},
	width: source === "carousel" ? "auto" : "100%",

	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		width: source !== "carousel" ? "48%" : "auto",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		width: source !== "carousel" ? "31.75%" : "auto",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 2K breakpoint  2560 ~~4 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: source !== "carousel" ? "23.75%" : "auto",
	},
}));

const BusinessProfileCardLink = styled("a", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	textDecoration: "none",
	cursor: "pointer",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:focus, &:hover, &:visited, &:link, &:active": {
		textDecoration: "none",
	},
	width: source === "carousel" ? "auto" : "100%",
	"@media (pointer: fine)": {
		transition: "all 0.2s ease",
		"&:hover": {
			// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
			boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
			transform: "scale(1.01)",
		},
	},

	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		width: source !== "carousel" ? "48%" : "auto",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		width: source !== "carousel" ? "31.75%" : "auto",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 2K breakpoint  2560 ~~4 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: source !== "carousel" ? "23.75%" : "auto",
	},
}));

const CardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0.75rem",
	gap: "1rem",
	borderRadius: "1rem",
	background: theme.palette.background.paper,
}));

const ColumnContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "0rem",
}));

const RowContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
}));

const ProfilePicture = styled("img")(({ theme }) => ({
	width: "4.5rem",
	height: "4.5rem",
	borderRadius: "0.75rem",
	objectFit: "cover",
	[theme.breakpoints.up("md1190")]: {
		height: "5.25rem",
		width: "5.25rem",
		borderRadius: "1rem",
	},
})) as any;

const BusinessTitle = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	fontWeight: 400,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
		lineHeight: "1.125rem",
	},
}));

const BusinessType = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	fontWeight: 400,
	lineHeight: "0.875rem",
	color: theme.palette.text.secondary,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "0.875rem",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 500,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.25rem",
	},
}));

const CustomChip = styled(Chip)(({ theme }) => ({
	"& .MuiChip-label": {
		padding: "0rem 0.5rem 0rem 0.5rem",
	},
	"& .MuiChip-avatar": {
		width: "1rem",
		height: "1rem",
	},
	height: "1.35rem",
	padding: "0rem",
	fontWeight: 500,
	fontSize: "0.75rem",
	borderRadius: "0.5rem",
	backgroundColor: "#dbf1e8",
	color: theme.palette.text.primary,
}));

const RatingContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.25rem",
}));

const ListDetailsContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	alignSelf: "stretch",
	width: "100%",
	padding: "0.5rem 0.5rem",
	borderRadius: "0.75rem",
	border: "1px solid #B05911",
	background: "#FFFFFF",
	boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
	cursor: "pointer",
	gap: "2.5rem",
}));

const HeadContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const BusinessProfileCardsSkeletonDesktop = ({ source }: { source: string }) => {
	const theme = useTheme();
	// this component is use for both mobile and desktop
	return (
		<BusinessProfileCardLinkSkeleton source={source ? source : ""}>
			<CardContainer source={source ? source : ""}>
				<RowContainer
					sx={{
						width: "100%",
						gap: "1rem",
						alignItems: "flex-start",
					}}
				>
					<Skeleton
						animation="wave"
						variant="rectangular"
						// width="7.5rem"
						// height="7.5rem"
						style={{
							width: "5rem",
							height: "5rem",
							borderRadius: "0.75rem",
							objectFit: "cover",
							[theme.breakpoints.up("md1190")]: {
								height: "5.25rem",
								width: "5.25rem",
								borderRadius: "1rem",
							},
						}}
					/>
					<ColumnContainer sx={{ gap: "0.5rem" }}>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="8rem"
							height="0.825rem"
							style={{ borderRadius: "0.25rem" }}
						/>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="4rem"
							height="0.825rem"
							style={{ borderRadius: "0.25rem" }}
						/>
					</ColumnContainer>
				</RowContainer>

				<RowContainer sx={{ gap: "1rem" }}>
					<Skeleton
						animation="wave"
						variant="rectangular"
						width="100%"
						height="0.825rem"
						style={{
							width: "7.25rem",
							height: "3.25rem",
							borderRadius: "0.75rem",
							objectFit: "cover",
						}}
					/>
					<Skeleton
						animation="wave"
						variant="rectangular"
						width="100%"
						height="0.825rem"
						style={{
							width: "7.25rem",
							height: "3.25rem",
							borderRadius: "0.75rem",
							objectFit: "cover",
						}}
					/>
				</RowContainer>
			</CardContainer>
		</BusinessProfileCardLinkSkeleton>
	);
};
export default BusinessProfileCardsSkeletonDesktop;
